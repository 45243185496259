<template lang="pug">
div(v-if="isReady")
  .card.card-custom.card-stretch.gutter-b
    .card-header.border-0.pt-5
      h3.card-title.align-items-start.flex-column
        span.card-label.font-weight-bolder.text-dark.
          {{ $t('ctd_data.parameter_list') }}
      .card-toolbar
        ul.nav.nav-pills.nav-pills-sm.nav-dark-75
          li
            b-button.btn.btn-sm.btn-info(@click="showAddModal()") {{ $t('GENERAL.add') }}
    .card-body.pt-3.pb-0
      .table-responsive
        table.table.table-borderless.table-vertical-center(v-if="data")
          thead
            tr
              th.p-0(style="width: 30%").
                {{ $t('ctd_data.parameter_name') }}
              th.p-0(style="width: 20%").
                {{ $t('ctd_data.parameter_unit') }}
              th.p-0(style="min-width: 10%").
                {{ $t('GENERAL.last_update') }}
              th.p-0.text-right(style="min-width: 10%").
                {{ $t('GENERAL.option') }}
          tbody
            template(v-for="(item, i) in data")
              tr(v-bind:key="i")
                td.pl-0
                  a.text-dark-75 {{ item.name }}
                td.pl-0
                  a.text-dark-75 {{ item.unit ? item.unit : '-' | str_limit(128) }}
                td.pl-0
                  div
                    a.text-muted.font-weight-bold {{ item.lu_datetime }}
                td.text-right.pr-0
                  b-button(@click="showEditModal(item.id)" class="btn btn-icon btn-light btn-sm mx-3")
                    span.svg-icon.svg-icon-md.svg-icon-primary
                      //begin::Svg Icon
                      inline-svg(src="media/svg/icons/Communication/Write.svg")
                      //end::Svg Icon
                  a(@click="deleteConfirmationModal(item.id)" class="btn btn-icon btn-light btn-sm" ref="btnShow")
                    span.svg-icon.svg-icon-md.svg-icon-danger
                      //begin::Svg Icon
                      inline-svg(src="media/svg/icons/General/Trash.svg")
                      //end::Svg Icon

        .text-center(v-else) No Data
      ParameterAddDataComp(ref="add_modal_comp")
.card.card-custom.card-stretch.gutter-b(v-else)
  .card-body.pt-3.p-20
    .d-flex.justify-content-center.mb-3
      b-spinner(label="Loading...")
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';
import ParameterAddDataComp from '../components/Parameter_add_data_comp.vue';

export default {
  components: {
    ParameterAddDataComp,
  },
  name: 'ctd_parameter_list',
  data() {
    return {
      isReady: false,
      data: null,
      total_post: 0,
      isShowModal: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'CTD Data', route: '/ctd'}, {title: 'Parameter'}]);

    this.$store
      .dispatch(Services.GET_PARAMETER_LIST)
      .then((res) => {
        this.isReady = true;
        this.data = res.data;
        this.total_post = res.data.length;
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  methods: {
    reloadData() {
      this.isReady = false;
      this.$store
        .dispatch(Services.GET_PARAMETER_LIST)
        .then((res) => {
          this.isReady = true;
          this.data = res.data;
          this.total_post = res.data.length;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    showAddModal() {
      this.$refs.add_modal_comp.showModal({mode: 'add'});
    },
    showEditModal(id) {
      this.$refs.add_modal_comp.showModal({mode: 'edit', id: id});
    },
    deleteConfirmationModal(id) {
      this.boxTwo = '';
      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isReady = false;
            // console.log('Deleting post confirmation: ' + id);
            this.$store
              .dispatch(Services.DELETE_PARAMETER, {id: id})
              // go to which page after successfully login
              .then((res) => {
                this.$store
                  .dispatch(Services.GET_PARAMETER_LIST)
                  // go to which page after successfully login
                  .then((res) => {
                    this.isReady = true;
                    this.data = res.data;
                    this.total_post = res.data.length;
                  })
                  .catch((error) => {
                    // console.log(error);
                  });
              })
              .catch((error) => {
                // console.log(error);
                // Swal.fire({
                //     title: '',
                //     text: error,
                //     icon: 'error',
                //     confirmButtonClass: 'btn btn-secondary',
                //     heightAuto: false,
                // });
              });
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style></style>
